<template>
  <div style="width: 100%" class="login">
    <!-- <template v-if="$store.state.login.isLoggedIn">
      <UserPage /> 
    </template>
    <template v-else>
      <LoginUser />
    </template> -->
    <LoginUser />
  </div>
</template>

<script>
import LoginUser from "@/components/login/LoginUser";
// import UserPage from "@/components/login/UserPage";
export default {
  components: {
    LoginUser,
    // UserPage,
  },
};
</script>

<style></style>
